<!-- 安全管理 -->
<template>
  <div>
    <el-card>
      安全性高的密码可以使账号更安全，建议您定期更换密码
      <div class="btn">
        <el-button size="small" @click="submit" class="submit"
          >修改密码</el-button
        >
      </div>
    </el-card>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <el-form
        ref="loginForm"
        :model="loginForm"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <el-form-item prop="phone" class="username">
          <el-input
            class="phone"
            ref="username"
            v-model="loginForm.phone"
            placeholder="请输入手机号码"
            name="phone"
            type="text"
            tabindex="1"
            clearable
          />
        </el-form-item>
        <el-form-item prop="code">
          <div class="inputs">
            <el-input
              v-model="loginForm.code"
              placeholder="请输入图形验证码"
              class="borderNone"
            ></el-input>
            <img :src="urls" alt @click="upImg" />
          </div>
        </el-form-item>
        <el-form-item prop="verificationCode">
          <div class="inputs">
            <el-input
              v-model="loginForm.verificationCode"
              placeholder="请输入验证码"
              class="borderNone"
            ></el-input>
            <el-button
              type="primary"
              size="small"
              @click="send"
              :disabled="(disabled = !show)"
            >
              <div style="width: 70px" v-show="show">获取验证码</div>
              <div style="width: 70px" v-show="!show" class="count">
                {{ count }} s
              </div>
            </el-button>
          </div>
        </el-form-item>
        <el-form-item prop="password" class="username">
          <el-input
            class="phone"
            ref="password"
            v-model="loginForm.password"
            placeholder="请设置登录密码"
            name="password"
            type="password"
            tabindex="1"
            clearable
          />
        </el-form-item>
        <el-form-item prop="newPassword" class="username">
          <el-input
            class="phone"
            ref="newPassword"
            v-model="loginForm.newPassword"
            placeholder="请再次设置登录密码"
            name="newPassword"
            type="password"
            tabindex="1"
            clearable
          />
        </el-form-item>
        <el-button
          :loading="loading"
          class="login-btn"
          type="primary"
          @click.native.prevent="handleLogin"
          >确认</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getImageCode, reportSms, updatePassword } from '@/api/login'

export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/
      if (value === '' || !reg.test(value)) {
        return callback(new Error('请输入正确格式的手机号码 '))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error('请输入六位验证码'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error('请输入最少六位登录密码'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      loginForm: {
        phone: '',
        password: '',
        verificationCode: '',
        code: '',
        newPassword: '',
        type:4
      },
      loginRules: {
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername,
          },
        ],
        verificationCode: [
          {
            required: true,
            trigger: 'blur',
            validator: validateCode,
          },
        ],
        code: [
          {
            required: true,
            trigger: 'blur',
            message: '请填写图形验证码',
          },
        ],
      },
      loading: false,
      timer: null,
      urls: '',
      show: true,
      count: '',
    }
  },
  methods: {
    submit() {
      this.dialogVisible = true
      if (this.dialogVisible == true) {
        this.upImg()
      }
    },
    upImg() {
      getImageCode().then((res) => {
        this.urls = res.data.img
        this.loginForm.uuid = res.data.uuid
      })
    },
    send() {
      const time_count = 60
      reportSms(this.loginForm)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success('发送成功')
            this.countDown(time_count)
          }
        })
        .catch((err) => {
          this.upImg()
        })
    },
    countDown(times) {
      if (!this.timer) {
        this.count = times
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= times) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer) // 清除定时器
            this.timer = null
          }
        }, 1000)
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.loginForm.password !== this.loginForm.newPassword) {
            this.$message({
              message: '两次密码不相同',
              type: 'warring',
            })
            return
          }
          this.loading = true
          updatePassword(this.loginForm)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: '修改密码成功',
                  type: 'success',
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 500)
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
<style lang='scss' scoped>
.btn {
  text-align: center;
  margin-top: 20px;
  .el-button {
    width: 150px;
  }
}
.inputs {
  display: flex;
  align-items: center;
  .el-button {
    margin-left: 20px;
  }
  .el-button--primary {
    background-color: #3c60bc;
    border-color: #3c60bc;
  }

  img {
    margin-left: 20px;
  }
}
.login-btn {
  width: 100%;
  background: #3c60bc;
  color: #fff;
  margin-bottom: 20px;
  border: none;
}
</style>